/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/leaflet@1.7.1/dist/leaflet.min.js
 * - /npm/jquery@3.5.1/dist/jquery.min.js
 * - /npm/leaflet-rotatedmarker@0.2.0/leaflet.rotatedMarker.min.js
 * - /npm/leaflet-groupedlayercontrol@0.6.1/dist/leaflet.groupedlayercontrol.min.js
 * - /npm/corejs-typeahead@1.3.1/dist/typeahead.bundle.min.js
 * - /npm/select2@4.0.13/dist/js/select2.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
